export default class UrlUtils {
    public getLastPathVariable = (url: string): string =>
        url.split('/').pop() || '';

    public getSecondLastPathVariable = (url: string): string => {
        const urlArr = url.split('/');
        urlArr.pop();

        return urlArr.pop() || '';
    }

    public toQueryString = (ids: string[]): string => {
        return Array.from(ids.values())
            .map(id => `id=${id}`)
            .join("&");
    }

    public addId = (ids: string[], id: string): string[] => {
        if (ids.find(id_ => id_ === id)) {
            return ids;
        } else {
            return [id].concat(ids);
        }
    }

    public removeId = (ids: string[], id: string): string[] => {
        return ids.filter(id_ => id_ !== id);
    }

    public fetchUrlParams = (url: string = window.location.href) => {
        const params = url.match(/([^?=&]+)(=([^(&|#)]*))/g)
        if (params === null) {
            return {}
        } else {
            return params.reduce(
                (a: any, v: string) => {
                    a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)
                    return a
                }, {}
            )
        }
    }
}
